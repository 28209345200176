@import "reset";
@import "fonts";
@import "colors";
@import "base";
@import "login";
@import "navigation";

.section {
  margin-bottom: 1em;

  &__title {
    font-size: 3rem;
    margin-bottom: .5em;
  }

  &__subtitle {
    font-size: 2.2rem;
    margin-bottom: .4em;
  }
}

.form {
  padding: .5em 0;

  &__error {
    font-style: italic;
    color: $color-error;
  }

  &__success {
    color: $color-success;
    font-weight: bold;
  }
}

// ant forms
.ant-form {

  .anticon-info-circle {
    margin-left: 10px;
  }

  .ant-form-item-label {
    width: 200px;
  }

  .ant-input {
    width: 240px;
  }

  .ant-select,
  .ant-input-number,
  .ant-input-number-input {
    width: 240px !important;
  }

  .ant-input-number-input-wrap {
    //width: 340px;
    //background: white;
  }

  .ant-form-item {
    input {
      //min-width: 400px;
    }
  }
}

// message Antd
.message {
  &__success {
    svg {
      fill: $color-success;
    }
  }

  &__error {
    svg {
      fill: $color-error;
    }

    span {
      display: inline-block;
      width: 100%;
      text-align: left;
    }
  }
}

