.navbar {
  width: 8rem;
  height: 100vh;
  position: fixed;
  background-color: $color-primary;
  transition: width 200ms ease;
  z-index: 12;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 8rem;
  color: $color-light;
  text-decoration: none;
  filter: grayscale(100%) opacity(0.9);
  transition: 600ms;

  &:hover {
    filter: grayscale(0%) opacity(1);
    background-color: $color-light;
    color: $color-dark;
  }

  svg {
    width: 4rem;
    margin: 0 1rem 0 2rem;
  }
}

.nav-item {
  .nav-link {
    &.active,
    &:hover {
      filter: grayscale(0%) opacity(1);
      background-color: $color-light;
      color: $color-dark;
    }
  }
}

.link-text {
  display: none;
  margin-left: 1rem;
  font-size: 2rem;
  font-weight: 500;
}

.navbar:hover {
  width: 24rem;
}

.navbar:hover .link-text {
  display: block;
}

path {
  color: $color-secondary;
  transition: 600ms;
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: $color-light;
  background-color: darken($color-primary, 10%);

  letter-spacing: 0.3ch;
  width: 100%;

  svg {
    transform: rotate(0deg);
    transition: transform 600ms;
  }

  .link-text {
    padding-left: 1rem;
    font-size: 2.5rem;
  }
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}
