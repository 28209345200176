.login {
  background-color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 200px;

  &__header {
  }

  &__logo {
    width: 200px;
  }

  &__container {
    border: 1px solid $color-primary;
    border-radius: 20px;
    background-color: $color-light;
    padding: 30px;
    width: 400px;
    position: relative;
    box-shadow: 10px 10px 20px 0 rgba(0,0,0,0.75);
  }

  &__form {
    color: $color-primary;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__field {
    width: 100%;
    position: relative;
    border-bottom: 2px dashed $color-primary;
    margin: 4rem auto 1rem;
    transition: 500ms;
  }

  &--label {
    color: $color-primary;
    font-size: 1.8rem;
    font-family: Montserrat, sans-serif;
  }

  &--input {
    outline: none;
    border: none;
    overflow: hidden;
    margin: 0;
    width: 100%;
    padding: 0.25rem 0;
    background: none;
    color: $color-primary;
    font-size: 2.4rem;
    font-weight: bold;
    transition: border 500ms;
  }

  /* Border animation */
  &__field::after {
    content: "";
    position: relative;
    display: block;
    height: 4px;
    width: 100%;
    background: $color-primary;
    transform: scaleX(0);
    transform-origin: 0;
    opacity: 0;
    transition: all 500ms ease;
    top: 2px;
  }

  &__field:focus-within {
    border-color: transparent;
  }

  &__field:focus-within::after {
    transform: scaleX(1);
    opacity: 1;
  }

  &--label {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-style: normal;
    position: absolute;
    transform: translateY(-3rem);
    transform-origin: 0;
    transition: transform 400ms;
  }

  &__field:focus-within .label,
  .input:not(:placeholder-shown) + .label {
    transform: scale(0.8) translateY(-8rem);
    opacity: 1;
  }

  &__error {
    margin: .5em 0 0 0;
    color: orangered;
    font-weight: bold;
    font-style: italic;
    font-size: 1.5rem;
  }

  .input:valid {
    color: $color-success;
  }

  .input:invalid {
    color: $color-error;
  }

}
