html {
  // 1rem = 10px on default settings
  font-size: 62.5%;
}

html, body {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-family: Montserrat, sans-serif;
}

#root {
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0.95rem;
}

body::-webkit-scrollbar-track {
  background-color: $color-light;
}

body::-webkit-scrollbar-thumb {
  background-color: $color-primary;
}

label {
  display: block;
}

input, select, textarea {
  font-family: inherit;
  padding: 0.4em;
  margin: 0 0 0.5em 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 2.2rem;
}

h1 {
  margin-bottom: .3em;
}

main {
  margin-left: 8rem;
  padding: 2em 4em;
  font-size: 1.8rem;
}

p {
  margin-bottom: .5em;
  width: 70%;
  max-width: 600px;
}

button {
  margin-top: 2rem;
  padding: 10px 30px;
  font-weight: bold;
  color: $color-light;
  background-color: $color-secondary;
  border-radius: 5px;
  transition: all 500ms;
  border: none;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: lighten($color-secondary, 20%);
}
