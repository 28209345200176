// thin 100
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

// thin italic 100
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

// extra light 200
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

// extra light italic 200
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

// light 300
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

// light italic 300
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

// regular 400
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// italic 400
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

// medium 500
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

// medium italic 500
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

// semi bold 600
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

// semi bold italic 600
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

// bold 700
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

// bold italic 700
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

// extra bold 800
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

// extra bold italic 800
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

// black 900
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

// black italic 900
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
